.CSVImporter_ColumnDragTargetArea__boxLabel{
    width: 200px !important;
    text-align: center !important;
}
.CSVImporter_ColumnDragTargetArea__box{
    width: 200px !important;
}
.CSVImporter_ColumnDragTargetArea{
    flex-wrap: nowrap !important;
    overflow-y: auto !important;
}