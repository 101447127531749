.autocomplete-id{
    width: 100px !important;
}

.tablePayrollContainer {
    position: relative;
    overflow: auto;
   
    height: 400px;
  }
  
  .tablePayrollContainer .table {
    width: 100%;
    border-collapse: collapse;
  }
  .tablePayrollContainer .table th{
    text-align: center;
  }
  .tablePayrollContainer .table th,
  .tablePayrollContainer .table td {
    padding: 8px 16px;
    border-bottom: 1px solid black;
    
  }
  

  .tablePayrollContainer .table th.sticky,
  .tablePayrollContainer .table td.sticky {
    position: -webkit-sticky;
    position: sticky;
    background: #fff;
    z-index: 1;
    
  }
  
  .tablePayrollContainer .table th.sticky {
    top: 0;
    
  }
  .tablePayrollContainer .table th.sticky.cont,
  .tablePayrollContainer .table td.sticky.cont,
  .tablePayrollContainer .table th.sticky.id,
  .tablePayrollContainer .table th.sticky.employee,
  .tablePayrollContainer .table td.sticky.id,
  .tablePayrollContainer .table td.sticky.employee {
    z-index: 2;
  }
  .tablePayrollContainer .table th.sticky.cont,
  .tablePayrollContainer .table td.sticky.cont {
    left: 0;
  }
  .tablePayrollContainer .table th.sticky.id,
  .tablePayrollContainer .table td.sticky.id {
    left: 40px;
  }
  
  .tablePayrollContainer .table th.sticky.employee,
  .tablePayrollContainer .table td.sticky.employee {
    left: 180px; /* Adjust based on the width of the id column */
  }
  
  .tablePayrollContainer .table th.sticky-id,
  .tablePayrollContainer .table th.sticky-cont,
  .tablePayrollContainer .table th.sticky-employee {
    z-index: 3;
  }
  .tablePayrollContainer.tableHeaderLabel{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 3;
    
  }
  .tablePayrollContainer.tableHeaderColumn{
    z-index: 3;
    position: -webkit-sticky;
    position: sticky;
    
  }

  .tablePayrollContainer .tableWithSearch {
    align-items: left !important;
    text-align: left !important;
    z-index: 3 !important;
    left: -20px;
    position: -webkit-sticky;
    position: sticky !important;
  }
  .tablePayrollContainer.tableWithSearch button{
    margin-top: -5px;
    margin-left: 10px;
  }
  
  .tablePayrollContainer .tableWithSearch .MuiTextField-root {
    margin-left: 20px;
    flex-grow: 1;
    width: 220px;
  }
  .totalPayroll td{
    font-weight: 700;
    font-size: 16px;
  }
  .payrollPaperContainer{
    height: 600px;
    padding: 20px;
  }
  .payrollPrepared{
    margin-top: 10px !important;
  }
  .no-border{
    border: none !important;
  }
  .withBorder{
    border: 1px solid black !important;
  }
@media print{
    .tablePayrollContainer{
        overflow: unset !important;
        height: 100% !important;
    }
    .payrollPaperContainer{
        overflow: unset !important;
        box-shadow: none !important;
        height: auto !important; 
        padding: 10px;
        height: 100% !important;
    }
    
    .tablePayrollContainer .table th, .tablePayrollContainer .table td{
        font-size: 14px !important;
    }
    .no-print{
        display: none !important;
    }
    .tablePayrollContainer .table th,
  .tablePayrollContainer .table td {
    padding: 2px 8px;
    font-weight: 700;
    
  }
}