.payslip-body .basicPay{
    width: 128px;
}
@media print {
    .payslip-container{
        margin-top: 5px !important;
        padding: 20px !important;
    }
    .payslip-header h6,.payslip-header p{
        font-weight: 700;
        font-size: 10px !important;
    }
    .payslip-name h6{
        font-weight: 700;
        font-size: 10px !important;
    }
    .payslip-body p{
        font-weight: 700;
        font-size: 10px !important;
    }
    .payslip-contri p{
        font-weight: 700;
        font-size: 10px !important;
    }
    .payslip-footer p{
        font-weight: 700;
        font-size: 14px !important;
    }
    .payslip-body .basicPay{
        width: 80px !important;
    }
    .holidayPay{
        width: 65px !important;
    }
    .holidayPayRight{
        width: 65px !important;
    }
    .basicPayRight{
        width: 80px !important;
    }
    .lessDeduc{
        width: 65px !important;
    }
    .lessDeducRight{
        width: 65px !important;
    }
    .contri{
        width: 60px !important;
    }
    .contriMiddle{
        width: 30px !important;
    }
    .contriRight{
        width: 40px !important;
    }
}