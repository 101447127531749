.employeeName input{
    text-align: center;
    font-weight: 700;
    width: 100%;
    color:black;

}
.employeeName input:disabled{
    color:black;
    -webkit-text-fill-color: black;
}
.dtrMonth{
    text-align: center;
    font-weight: 700;
    width: 100%;
}
.dtrMonth input:disabled{
    color:black;
    -webkit-text-fill-color: black;
}
.dtrMonth input{
    text-align: center;
    font-weight: 700;
}
.tableDTR tr th, .tableDTR tr td{
    padding: 2px !important;
    text-align: center;
    font-weight: 700;
}
.tableDTR tr td input, .tableDTR tr td {
    text-align: center;
    font-weight: 700;
    padding: 2px !important;
}
.tableDTR tfoot tr td h6{
    text-align: center;
    font-weight: 700;
    padding: 2px !important;
    display: inline-flex;
}
.tableDTR tfoot tr td {
    text-align: center;
    font-weight: 700;
    color: black;
    padding: 2px !important;
    position: relative;
}
.iconMenuBtn,.iconMenuBtn li{
    border-radius: 5px;
}
.iconMenuBtn, .iconMenuBtn svg{
    background-color: #0E46A3;
    color: white !important;
    font-weight: 700 !important;
}
.iconMenuBtn:hover, .iconMenuBtn:hover svg{
    background-color: white !important;
    color: #0E46A3 !important;
    font-weight: 700 !important;
}
@media print {
    .dtrCopy{
        display: block !important;
    }

    .employeeName input{
        text-align: center;
        font-weight: 700;
        width: 100%;
        color:black;
        font-size: 10px !important;
    
    }
    .employeeName input:disabled{
        color:black;
        -webkit-text-fill-color: black;
        font-size: 10px !important;
    }
    .dtrMonth{
        text-align: center;
        font-weight: 700;
        width: 100%;
        font-size: 10px !important;
    }
    .dtrMonth input:disabled{
        color:black;
        -webkit-text-fill-color: black;
        font-size: 10px !important;
    }
    .dtrMonth input{
        text-align: center;
        font-weight: 700;
        font-size: 10px !important;
    }
    .tableDTR tr th, .tableDTR tr td{
        padding: 2px !important;
        text-align: center;
        font-weight: 700;
        font-size: 8px !important;
    }
    .tableDTR tr td input, .tableDTR tr td {
        text-align: center;
        font-weight: 700;
        padding: 2px !important;
        font-size: 8px !important;
    }
    .tableDTR tfoot tr td h6{
        text-align: center;
        font-weight: 700;
        padding: 2px !important;
        display: inline-flex;
        font-size: 8px !important;
    }
    .tableDTR tfoot tr td {
        text-align: center;
        font-weight: 700;
        color: black;
        padding: 2px !important;
        right: 10px !important;
        position: relative;
        font-size: 8px !important;
    }
    .removeShadow{
        box-shadow: none !important;
    }
    .dtrPrintContainer{
        padding: 20px !important;
    
    }
    .dtrPrintContainer p{
        font-size: 10px !important;
    
    }
    .dtrPrintContainer h6{
        font-size: 8px !important;
    }
    .page-break {
        page-break-before: always;
    }
}
/*.css-lgacg7-MuiTableCell-root{
    padding:2px !important;
}
.css-1jh44k9-MuiTableCell-root{
    padding:2px !important;
    width: 100px;
    text-align: center !important;
    font-weight: 700 !important;
}
.css-1b2yzt9-MuiTableCell-root{
    padding:2px !important;
    font-weight: 700 !important;
}
.css-k636zq-MuiTableCell-root{
    padding:2px !important;
}
.css-1ifneef-MuiTableCell-root{
    padding:2px !important;
}
.css-apqrd9-MuiTableBody-root tr td{
    text-align: center !important;
    font-weight: 600;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled{
    color: black !important;
    -webkit-text-fill-color: black !important;
    text-align: center;
    border-bottom: 1px solid black;
    font-weight: 700;
}
.css-1j0qgw8-MuiAutocomplete-root{
    width: 100% !important;
}
.css-1j0qgw8-MuiAutocomplete-root .MuiInput-root .MuiInput-input{
    font-weight: 700;
    text-align: center;
}
.css-w5b4g9-MuiTableCell-root{
    padding:2px !important;
    font-weight: 700 !important;
}
.tableDTR .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    font-weight: 700;
    text-align: center;
    padding:2px !important;
}
.css-10hburv-MuiTypography-root{
    text-align: center;
    font-weight: 700 !important;
}
@media print {
    .css-apqrd9-MuiTableBody-root tr td{
        text-align: center !important;
        font-weight: 600;
        font-size: 8px !important;
    }
    .css-1jh44k9-MuiTableCell-root{
        font-size: 8px !important;
    }
    .css-c55f9w-MuiTypography-root{
        font-size: 12px !important;
    }
    .css-d903f3-MuiTypography-root{
        font-size: 12px !important;
    }
    .css-1wop4mx-MuiTypography-root{
        font-size: 12px !important;
    }
    .css-1fzw1f-MuiTypography-root{
        font-size: 12px !important;
    }
    .css-1kn56m5-MuiTypography-root{
        font-size: 12px !important;
    }
    .css-r8dlkf-MuiTypography-root{
        font-size: 10px !important;
    }
    .css-13owvxy-MuiTypography-root{
        font-size:12px !important;
    }
    .css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled{
        font-size: 12px !important;
    }
    .css-1b2yzt9-MuiTableCell-root{
        font-size: 8px !important;
    }
    .removeShadow{
        box-shadow: none !important;
    }
    .dtrCopy{
        display: block !important;
    }
    .css-exfl4s-MuiGrid-root{
        padding:20px !important;
    }
}*/
.dtrCopy{
    display: none;
}