@media (max-width: 600px) {
    .personal-info-grid {
      display: flex;
      flex-direction: column;
    }
  
    .move-to-first-column {
      order: -1;
    }
    .personalInfoName{
      width: 150px !important;
    }
  }
  .positionSelect{
    margin-top: 0px !important;
  }