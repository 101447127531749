.autocomplete-id{
    width: 100px !important;
}

.tableContainer {
    position: relative;
    overflow: auto;
    max-height: 400px;
    height: 400px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .tableContainer .table th,
  .tableContainer  .table td {
    padding: 8px 16px;
    text-align: center;
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
  }
  

  .tableContainer  .table th.sticky,
  .tableContainer .table td.sticky {
    position: -webkit-sticky;
    position: sticky;
    background: #fff;
    z-index: 1;
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
  }
  
  .tableContainer  .table th.sticky {
    top: 0;
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
  }
  .tableContainer .table th.sticky.cont,
  .tableContainer  .table td.sticky.cont,
  .tableContainer  .table th.sticky.id,
  .tableContainer .table th.sticky.employee,
  .tableContainer .table td.sticky.id,
  .tableContainer  .table td.sticky.employee {
    z-index: 2;
  }
  .tableContainer  .table th.sticky.cont,
  .tableContainer .table td.sticky.cont {
    left: 0;
  }
  .tableContainer .table th.sticky.id,
  .tableContainer  .table td.sticky.id {
    left: 40px;
  }
  
  .tableContainer  .table th.sticky.employee,
  .tableContainer .table td.sticky.employee {
    left: 180px; /* Adjust based on the width of the id column */
  }
  
  .tableContainer .table th.sticky-id,
  .tableContainer .table th.sticky-cont,
  .tableContainer .table th.sticky-employee {
    z-index: 3;
  }
  .tableContainer .tableHeaderLabel{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 3;
    font-weight: 700;
  }
  .tableContainer .tableHeaderColumn{
    z-index: 3;
    position: -webkit-sticky;
    position: sticky;
    
  }

  .tableContainer .tableWithSearch {
    align-items: left !important;
    text-align: left !important;
    z-index: 3 !important;
    left: -20px;
    position: -webkit-sticky;
    position: sticky !important;
  }
  .tableContainer .tableWithSearch button{
    margin-top: -5px;
    margin-left: 10px;
  }
  
  .tableContainer  .tableWithSearch .MuiTextField-root {
    margin-left: 20px;
    flex-grow: 1;
    width: 220px;
  }
.disabledTextField input{
  -webkit-text-fill-color:black !important;
}
.createPayrollNew .css-1sazv7p-MuiStack-root>:not(style)~:not(style){
  margin-top: 6px !important;
}
.createPayrollNew .css-pl8gqh-MuiStack-root>:not(style)~:not(style){
  margin-left: 4px !important;
}
.createPayrollNew input::placeholder{
  font-size: 13px;
}
.createPayrollNew label{
  font-size: 13px;
}
.createPayrollNew input:disabled{
  -webkit-text-fill-color: black !important;
}
.createTablePayrollNew th{
  font-weight: 700;
}
.adjustFont label, .adjustFont input{
  color: red;
}
.adjustFont fieldset{
  border: 2px solid red;
}
.adjustFont fieldset:hover, .adjustFont fieldset:focus, .adjustFont label:hover, .adjustFont label:focus{
  border: 2px solid red;
}
.createPayrollNew .css-jj2ztu > :not(style) ~ :not(style){
  margin-top: 6px !important;
}