.table13th{
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}
.table13th th{
    border: 1px solid #000;
    padding: 5px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
}
.table13th td{
    border: 1px solid #000;
    padding: 5px;
    text-align: center;
    font-weight: 700;
}
.daily_rate{
    text-align: right;
}
.amount{
    text-align: right;
}

/* Styles for the sticky ID and Name columns */
.sticky-column {
    position: sticky;
    background-color: white; /* Maintain background color to avoid overlap issues */
    z-index: 2; /* Ensure the sticky column stays above the scrolling content */
    border-right: 1px solid #000; /* Preserve the table borders */
    border: 1px solid #000;
  }
  
  /* ID column will stick on the far left */
  .sticky-id {
    left: 0;
    min-width: 50px; /* Set width for the ID column */
    max-width: 50px;
    border-left: 1px solid #ddd; /* Add left border to avoid breaking the table layout */
  }
  
  /* Name column will stick after the ID column */
  .sticky-name {
    left: 50px;
    min-width: 300px; /* Set width for the Name column */
    max-width: 300px;
  }
  .darText{
    display: none;
  }
  @media print{
    .sticky-column{
        position: relative !important;
        z-index: unset !important;
        border: 1px solid !important;
        background-color: unset !important;
    }
    .darText{
        display: block;
    }
    .dar{
        display: none !important;
    }
    .sticky-id{
        left: unset !important;
        min-width: unset !important;
        max-width: unset !important;
        border-left: 1px solid !important;
    }
    .sticky-name{
        left: unset !important;
        min-width: unset !important;
        max-width: unset !important;
        border-left: 1px solid !important;
    }
    .table13th{
        overflow: unset !important;
        height: 100% !important;
    }
    .payrollPaperContainer{
        overflow: unset !important;
        box-shadow: none !important;
        height: auto !important; 
        padding: 10px;
        height: 100% !important;
    }
    
    .tablePayrollContainer .table13th th, .tablePayrollContainer .table13th td{
        font-size: 12px !important;
    }
    .no-print{
        display: none !important;
    }
    .tablePayrollContainer .table13th th,
  .tablePayrollContainer .table13th td {
    padding: 2px 8px;
    font-weight: 700;
  }
  .tableCellName{
    width: 150px !important;
  }
}