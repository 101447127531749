/* ApplicationForm.css */
body,
input,
textarea,
select,
button,
typography,
.AppFormPrintContainer {
  font-family: Arial, sans-serif;
}
